import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import Slider from "react-slick"
import Layout from "../components/layout"
import AIGrowthRightRoundOne from "../images/AIGrowthRightRoundOne.png"
import Rect from "../images/Rectangle.svg"
import ArrowIcon from "../images/circle-arrow-right.svg"
import "../styles/ai-growth.css"
import VideoBanner from "../video/AI-Page-Header-banner-Desktop.mp4"
import MobileVideoBanner from "../video/AI-Page-Header-banner-Mobile.mp4"
import EffortlessImage from "../video/ai-growth/EffortlessImage.mp4"
import HumanLikeVoice from "../video/ai-growth/HumanLikeVoice.mp4"
import PreAnalysisUserBehavior from "../video/ai-growth/PreAnalysisUserBehavior.mp4"
import StunningTypograpy from "../video/ai-growth/StunningTypograpy.mp4"
import VideoGaze from "../video/ai-growth/VideoGaze.mp4"
import AIAccelerated from "../video/productlab.mp4"

// import AIPowered from "../video/AI_Powered.mov"
const slideData = [
  {
    category: "Video Editing",
    cardTitle: "Video gaze correction",
    video: VideoGaze,
    description:
      "Ensuring the subject in the video keeps eye contact with the camera to engage",
    title: "Video Gaze Correction",
    useCase:
      " Ensuring the subject in the video maintains eye contact with the camera for a more engaging viewer experience.",
    AISolution:
      " “Descript” analyzes facial features and adjusts the subject's gaze, ensuring they maintain eye contact with the viewer throughout the video.",
    Outcome:
      " Improved viewer engagement by 27%, as it increased the connection between the subject and the audience and enhanced overall video quality.",
    percent: "27%",
    cardDescription:
      " Ensuring the subject in the video keeps eye contact with the camera to engage",
    outcome: "increase in viewer engagement",
  },
  {
    category: "Product Discovery",
    img: Rect,
    video: AIAccelerated,
    cardTitle: "AI-accelerated UI/UX Discovery",
    description:
      "Streamlining the discovery phase of UX/UI design projects to gather crucial ",
    title: "AI-accelerated UX/UI Discovery",
    useCase:
      " Streamlining the discovery phase of UX/UI design projects to gather crucial insights quickly when facing tight product delivery deadlines.",
    AISolution:
      " “Product Lab” - Combines user behavior analysis, sentiment analysis, and predictive modeling to uncover user preferences and pain points.",
    Outcome:
      " Reduced time spent on the discovery phase by 40%, data-driven insights for informed design decisions, and faster overall product delivery for clients.",
    cardDescription:
      "Streamlining the discovery phase of UX/UI design projects to gather crucial",
    percent: "40%",
    outcome: "increase in efficiency",
  },
  {
    category: "Video Editing",
    img: Rect,
    video: HumanLikeVoice,
    cardTitle: "Human-like voiceovers",
    description:
      "Finding human-like voiceovers for videos without needing live",
    title: "Human-like Voiceovers",
    useCase:
      " Finding human-like voiceovers for videos without needing live recordings.",
    AISolution:
      " “Elevenlabs” instantly generates natural AI voices in any language.",
    Outcome:
      " Reduced production time by 30% and costs by 20%. It also increased versatility in voiceover options and improved overall audio quality for client videos.",
    cardDescription:
      "Finding human-like voiceovers for videos without needing live",
    percent: "30%",
    outcome: "time saved",
  },
  {
    category: "Design Optimization",
    img: Rect,
    video: PreAnalysisUserBehavior,
    cardTitle: "AI-powered Pre-analysis of User",
    description:
      "Understanding user behavior and identifying areas of improvement in the",
    title: "AI-powered Pre-analysis of User Behavior",
    useCase:
      " Understanding user behavior and identifying areas of improvement in the website design before launch.",
    AISolution:
      " Attention Insight has a predictive eye-tracking technology to show heatmaps of where customers look. ",
    Outcome:
      " Faster identification of UX issues, data-driven design optimizations, and improved website performance by 40% without extensive manual analysis.",
    cardDescription:
      "Understanding user behavior and identifying areas of improvement in the",
    percent: "40%",
    outcome: "improvement in website performance",
  },
  {
    category: "Graphic Design",
    img: Rect,
    video: EffortlessImage,
    cardTitle: "Effortless Image Extension",
    description:
      "Extending an image by adding more relatable objects to enhance the",
    title: "Effortless Image Extension",
    useCase:
      " Extend an image by adding more relatable objects to enhance the visual context and storytelling.",
    AISolution:
      " Photoshop.ai harnesses AI to analyze the image content and generate contextually relevant extensions in seconds.",
    Outcome:
      " Faster and easier image enhancement, saving 40% valuable time and effort in the design process.",
    cardDescription:
      "Extending an image by adding more relatable objects to enhance the",
    percent: "40%",
    outcome: "reduction in time",
  },
  {
    category: "Graphic Design",
    img: Rect,
    video: StunningTypograpy,
    cardTitle: "Stunning Typography Effects",
    description:
      "Creating beautiful and unique typography effects for posters, social media",
    title: "Stunning Typography Effects",
    useCase:
      " Create beautiful and unique typography effects for posters, social media graphics, and other marketing materials.",
    AISolution:
      " Adobe Firefly - Utilizes AI to generate stunning typography designs in seconds, based on user input and design preferences.",
    Outcome:
      " Faster creation of eye-catching typography, reducing design time by 50% and enabling designers to explore more creative options.",
    cardDescription:
      "Creating beautiful and unique typography effects for posters, social media",
    percent: "50%",
    outcome: "time saved",
  },
  // Add more slide data as needed
]

const ModalSlider = ({ slides, selectedIndex, open }) => {
  const sliderRef = useRef(null)
  const videoRefs = useRef(slides.map(() => React.createRef()))
  const [currentSlideIndex, setCurrentSlideIndex] = useState(selectedIndex || 0)

  useEffect(() => {
    if (sliderRef.current && open) {
      sliderRef.current.slickGoTo(selectedIndex)
    }
  }, [open, selectedIndex])

  const handleBeforeChange = (currentSlide, nextSlide) => {
    // Pause the video of the previously active slide
    const prevVideoRef = videoRefs.current[currentSlideIndex]
    if (prevVideoRef.current) {
      prevVideoRef.current.pause()
    }
    setCurrentSlideIndex(nextSlide)
  }

  const CustomPrevArrow = ({ onClick, className, style }) => (
    <div
      onClick={onClick}
      className={className}
      style={{ ...style, display: "block" }}
    >
      <span>
        <i className="ptwo ptwo-newarrow"></i>
      </span>
    </div>
  )

  const CustomNextArrow = ({ onClick, className, style }) => (
    <div
      onClick={onClick}
      className={className}
      style={{ ...style, display: "block" }}
    >
      <span>
        <i className="ptwo ptwo-newarrow"></i>
      </span>
    </div>
  )

  const modalSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "ModalSlider",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: handleBeforeChange,
  }

  return (
    <Slider ref={sliderRef} {...modalSettings}>
      {slides.map((slide, index) => (
        <div key={index} className="ModalSlideWrap">
          <div className="imgWrap">
            <video
              ref={videoRefs.current[index]}
              width="100%"
              autoPlay={false}
              loop={false}
              className="video-js"
              controls={true}
            >
              <source src={slide.video} type="video/mp4" />
            </video>
          </div>
          <div className="contentWrap">
            <div className="Content">
              <h4>{slide.title}</h4>
              <ul>
                <li>
                  <strong>Use Case:</strong>
                  {slide.useCase}
                </li>
                <li>
                  <strong>AI Solution:</strong>
                  {slide.AISolution}
                </li>
                <li>
                  <strong>Outcome:</strong>
                  {slide.Outcome}
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

const AIGrowthTech = () => {
  const [open, setOpen] = useState(false)
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(null)

  const onOpenModal = index => {
    console.log("Index", index)
    setSelectedSlideIndex(index)
    setOpen(true)
    document.body.classList.add("modal-open")
  }

  const onCloseModal = () => {
    setOpen(false)
    document.body.classList.remove("modal-open")
  }

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Ai Growth Tech | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
        />
        <body className="aiGrowth" />
      </Helmet>
      <Layout>
        <div class="ai-growth-tech content-creative">
          <section id="Banner">
            <div className="banner-cover">
              <video
                width="100%"
                playsInline="playsinline"
                muted="muted"
                preload="yes"
                autoPlay="autoplay"
                loop="loop"
                className="video-js BiggerScreen"
                data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
              >
                <source src={VideoBanner} type="video/mp4" />
              </video>
              <video
                width="100%"
                playsInline="playsinline"
                muted="muted"
                preload="yes"
                autoPlay="autoplay"
                loop="loop"
                className="video-js SmallerScreen"
                data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
              >
                <source src={MobileVideoBanner} type="video/mp4" />
              </video>
            </div>
            <div className="banner-content">
              <div className="container">
                <div className="content-wrap">
                  <h1>AI synergy drives marketing breakthroughs</h1>
                  <p>
                    AI is a catalyst for a marketing revolution. As AI
                    technologies continue to advance, they are fundamentally
                    changing the way we approach marketing.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <p className="HiglightIntro">
                At Position<sup>2</sup>, we recognize the immense potential of
                AI in marketing and are committed to staying at the forefront of
                this transformative technology. Our team of experts continuously
                explores and integrates the latest AI advancements to help our
                clients stay ahead of the curve and achieve unparalleled success
                in their marketing endeavors.
              </p>
              <h2>Decoding the AI marketing matrix</h2>
              <p>
                We don't just follow the AI trend; we pioneer new frontiers in
                AI-driven marketing. Our cutting-edge strategies and innovative
                use cases demonstrate our commitment to harnessing the full
                potential of AI, delivering unparalleled growth and success for
                our clients.
              </p>
            </div>
          </section>
          <section id="AiUseCase">
            <div className="container">
              <h2>
                AI GrowthTech use cases in marketing by Position<sup>2</sup>
              </h2>
              <p>
                Delve into specific examples of how Position<sup>2</sup> employs
                AI technologies to solve marketing challenges, enhance campaign
                effectiveness, and drive growth for clients. Each use case
                illustrates the problem, the AI-driven solution, and the
                outcomes achieved.
              </p>
              <div className="slider-container">
                <Slider {...settings} id>
                  {slideData.map((slide, index) => (
                    <div key={index} className="eachCard">
                      <div className="Scaler"></div>
                      <div className="Wrapper">
                        <div class="overlay"></div>
                        <p>{slide.category}</p>
                        <h3>{slide.title}</h3>
                        <h5>{slide.description}</h5>
                        <hr />
                        <h3 className="percent">{slide.percent}</h3>
                        <h6>{slide.outcome}</h6>
                        <img
                          onClick={() => onOpenModal(index)}
                          className="CardArrow"
                          src={ArrowIcon}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <Modal
              open={open}
              onClose={onCloseModal}
              center
              classNames="AIGrowthTechModal"
            >
              <ModalSlider
                slides={slideData}
                selectedIndex={selectedSlideIndex}
                open={open}
              />
            </Modal>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>
                    Embracing the AI revolution for unparalleled marketing
                    success
                  </h2>
                  <p>
                    We are not just adopters of AI; we are pioneers, innovators,
                    and thought leaders. While we are redefining the landscape
                    of marketing, one AI-powered solution at a time, our
                    commitment to stay at the forefront of AI innovation enables
                    us to anticipate the trends of tomorrow.
                  </p>
                  <p>
                    We are constantly exploring new ways to leverage AI tech,
                    pushing the boundaries of what's possible in the realm of
                    marketing.
                  </p>
                </div>
                <div class="img-wrap">
                  <img alt="Copy write" src={AIGrowthRightRoundOne} />
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Ready to transform your marketing strategy with AI?</h2>
              <a className="button" id="3dsolutionvideo" href="/contact-us/">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default AIGrowthTech
